<template>
    <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M3.5999 2.00039C3.5999 2.87948 2.87899 3.60039 1.9999 3.60039C1.12082 3.60039 0.399902 2.87948 0.399902 2.00039C0.399902 1.1213 1.12082 0.400391 1.9999 0.400391C2.87899 0.400391 3.5999 1.1213 3.5999 2.00039ZM0.399902 8.00039C0.399902 7.1213 1.12082 6.40039 1.9999 6.40039C2.87899 6.40039 3.5999 7.1213 3.5999 8.00039C3.5999 8.87948 2.87899 9.60039 1.9999 9.60039C1.12082 9.60039 0.399902 8.87948 0.399902 8.00039ZM0.399902 14.0004C0.399902 13.1213 1.12082 12.4004 1.9999 12.4004C2.87899 12.4004 3.5999 13.1213 3.5999 14.0004C3.5999 14.8795 2.87899 15.6004 1.9999 15.6004C1.12082 15.6004 0.399902 14.8795 0.399902 14.0004Z"
              fill="#ABB2C8"/>
    </svg>
</template>

<style scoped>
    svg {
        height: 20px;
    }
</style>
